import React, { useState, useEffect, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Fill, ReExtProvider } from '@gusmano/reext';
import { Auth0Provider } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import 'moment-timezone';
import numeral from 'numeral';
import UserMention from '../helpers/mention/Mention';
import WebViewer from '@pdftron/webviewer';
import * as Sentry from '@sentry/react';
import Preloader from './preloader/Preloader';
import { GrowthBook } from '@growthbook/growthbook-react';
import { Loader } from '@googlemaps/js-api-loader';

import { SentryFeedBack, initSentry } from './sentry';

const isProduction = import.meta.env.NODE_ENV === 'production';

if (isProduction) {
    const SENTRY_DSN = import.meta.env.SENTRY_DSN;
    initSentry(SENTRY_DSN);
}

// Load Google Maps API
const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    //...additionalOptions,
});

loader
    .load()
    .then(() => {
        window.google = window.google || {};
    })
    .catch((e) => {
        console.error('Error loading Google Maps', e);
    });

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    api_host: import.meta.env.VITE_MIXPANEL_API_HOST,
    batch_requests: true,
});

moment.suppressDeprecationWarnings = true;

window.moment = moment;
window.mixpanel = mixpanel;
window.numeral = numeral;
window.Mention = UserMention;
window.WebViewer = WebViewer;
window.Sentry = Sentry;
window.SentryFeedBack = SentryFeedBack;
window.google = google;

if (import.meta.env.VITE_ENABLE_FEATURE_FLAGS) {
    const gb = new GrowthBook({
        apiHost: import.meta.env.VITE_GROWTHBOOK_API,
        clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
        enableDevMode: true,
    });

    gb.init({
        streaming: false,
    });
    window._growthbook = gb;
}

// Lazy load the App component
const LazyApp = lazy(() => import('./App'));

const RootComponent = () => {
    const [ReExtData, setReExtData] = useState(null);

    // Load ReExtData first
    useEffect(() => {
        const loadReExtData = async () => {
            window.Env = await import('./env');
            const { default: reExtData } = await import('../reextdata');
            setReExtData(reExtData);
        };

        loadReExtData();
    }, []);

    if (!ReExtData) {
        return Fill() && <Preloader />; // Show loading until ReExtData is available
    }

    return (
        Fill() && (
            <Auth0Provider
                domain={import.meta.env.VITE_AUTH0_DOMAIN}
                clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                audience={import.meta.env.VITE_AUTH0_AUDIENCE}
                authorizationParams={{
                    redirect_uri: window.location.href,
                }}>
                <ReExtProvider ReExtData={ReExtData}>
                    <Suspense fallback={<Preloader />}>
                        <LazyApp />
                    </Suspense>
                </ReExtProvider>
            </Auth0Provider>
        )
    );
};

ReactDOM.createRoot(document.getElementById('root')).render(<RootComponent />);
