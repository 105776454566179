import * as Sentry from '@sentry/react';

export const SentryFeedBack = Sentry.feedbackIntegration({
    // Additional SDK configuration goes in here, for example:
    isEmailRequired: true,
    triggerLabel: '',
    showBranding: false,
    useSentryUser: {
        name: 'fullName',
        email: 'email',
    },
    addScreenshotButtonLabel: 'Create Screenshot',
    autoInject: false,
});

export const initSentry = (dsn) => {
    if (!dsn) {
        return;
    }

    Sentry.init({
        dsn,

        // Send all error events
        sampleRate: 1,
        // Note: Needed so Sentry.httpClientIntegration() can properly collect headers and cookies
        sendDefaultPii: true,
        // Prevents tracking of replays for each event
        replaysSessionSampleRate: 0,
        // Enables tracking of replays for each error event
        replaysOnErrorSampleRate: 1,

        integrations: [
            // Enables video-like reproduction of user sessions
            Sentry.replayIntegration(),

            // Reports error events on failed HTTP requests
            Sentry.httpClientIntegration(),

            // Report error events on console logs filtered out by their level
            Sentry.captureConsoleIntegration(['error']),

            // Enables the sentry feedback widget
            SentryFeedBack,
        ],

        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception && event.event_id) {
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    title: 'Something went wrong',
                    user: {
                        name: window.CurrentUser.get('full_name'),
                        email: window.CurrentUser.get('email'),
                    },
                });
            }
            return event;
        },
    });
};
